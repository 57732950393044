/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ./images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('./images', true)
// const imagePath = (name) => images(name, true)


import * as bootstrap from 'bootstrap';
import Rails from '@rails/ujs';  // Import the rails-ujs library
Rails.start();  // Initialize rails-ujs

$(document).ready(function() {
    $(".dropdown-toggle").dropdown();
});
$(function () {
    $('[data-toggle="tooltip"]').tooltip()
});


document.addEventListener('turbo:load', () => {
    console.log('Turbo has loaded the page!');
});
document.addEventListener('turbo:before-stream-render', () => {
    console.log('Before stream render!');
  });
  
  document.addEventListener('turbo:stream-render', () => {
    console.log('Stream rendered!');
  });
Turbo.session.drive = true;


window.onerror = function(message, source, lineno, colno, error) {
  // Prepare error data
  var errorData = {
      message: message,
      source: 'web',
      backtrace: error ? error.stack : null
  };

  // Send error data to backend
  $.ajax({
      url: '/errors',
      type: 'POST',
      data: JSON.stringify(errorData),
      contentType: 'application/json',
      success: function(response) {
          console.log('Error sent to backend successfully.');
      },
      error: function(xhr, status, error) {
          console.error('Error sending error to backend:', error);
      }
  });
};